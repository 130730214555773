
import mixins from 'vue-typed-mixins';
import ProfileManagementFormMixin from '@/resources/profile-management/components/forms/ProfileManagementFormMixin';
import { SelectOption } from '@/types';

type Item = SelectOption & {disabled?: boolean}

export default mixins(ProfileManagementFormMixin).extend({
  name: 'SupplierPortalLinkedMastersDropdown',

  props: {
    source: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    hasAttached: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasErrors: false,
    };
  },

  computed: {
    options(): Array<SelectOption & {disabled?: boolean}> {
      const { options } = this.source;

      if (options.length === 0) {
        return [
          {
            optionLabel: this.$t('noOptionsAvailable') as string,
            optionValue: '',
            optionDisabled: true,
          },
        ];
      }

      return options;
    },
  },

  methods: {

    async handleOptionEmpty() {
      const { validationProvider, inputShadow, form } = this.$refs as any;

      await validationProvider.validate(inputShadow);

      if (!this.hasAttached) {
        form.setErrors({
          validationProvider: [this.$t('profileManagement.form.dropdownLinkValidation.msnAddValueSelected') as string],
        });
      }
    },

    emitHasError(errors: string[]) {
      const validationHasErrors = errors.length > 0;
      if (this.hasErrors !== validationHasErrors) {
        this.hasErrors = validationHasErrors;
        this.$emit('hasErrors', this.hasErrors);
      }
    },
  },
});
